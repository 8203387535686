<template>
    <click-to-edit
      v-model="scope.row.name"
      placeholder="Внесите название цели"
      :disabled="!isFieldAvailableForEdit(scope.row, 'name')"
      style="width: 100%"
      type="textarea"
      :word-limit="$companyConfiguration.settings.getElementsNameMaxLength()===false ? 0 :$companyConfiguration.settings.getElementsNameMaxLength()"
      :show-word-limit="!!$companyConfiguration.settings.getElementsNameMaxLength()"
      show-word-limit
      @input="saveElementPropChange(scope.row, 'name')"
    ></click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-name-no-desc-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
